import React from 'react'
import portrait from '../assets/512x512.png'
import data from '../data'
import { motion, useIsPresent } from "framer-motion";
import homeSvg from '../assets/blob.svg'


const Home = () => {
    const isPresent = useIsPresent();
    return (
        <>
            <img className='svg-top fadeRightMini' src={homeSvg} alt="svg" />
            <img className='svg-bottom fadeLeftMini' src={homeSvg} alt="svg" />
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: .5, ease: 'easeIn' } }}
                exit={{ scaleX: 1, transition: { duration: .5, ease: "easeOut" } }}
                style={{ originX: isPresent ? 0 : 1, backgroundColor: '#ed143d' }}
                className="privacy-screen"
            />
            <div className='homepage'>
                <img className='fadeLeftMini' src={portrait} alt="Cosmin Dumitriu" />
                <div className='name-title fadeRightMini'>
                    <h1>Cosmin Dumitriu</h1>
                    <h2>Frontend Developer </h2>
                    <blockquote className="blockquote">
                        <div className="blockquote-footer mt-2 fw-bold">Currently learning <cite title="Apple development">Swift</cite></div>
                    </blockquote>
                    <div className='contacts d-flex align-items-center justify-content-center'>
                        {data.socials.map((contact, index) => {
                            return (
                                <a href={contact.link} rel="noreferrer" target='_blank' key={index} className='contact'>
                                    <img src={contact.icon} alt={contact.alt} />
                                </a>
                            )
                        })
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default Home