import React from "react";
import { motion, useIsPresent, useScroll, useSpring } from "framer-motion";
import Card from "./Card";
import projectSvg from '../../assets/blob2.svg';
import data from "../../data";

function Projects() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });
  const isPresent = useIsPresent();


  return (
    <div className="projects position-relative pb-3">
      <img className='svg-top fadeRightMini' src={projectSvg} alt="svg" />
      <img className='svg-bottom fadeLeftMini' src={projectSvg} alt="svg" />
      <div className='text-center mb-3 mt-5'>
        <h2 className='fw-bold d-inline section-title px-2'>projects</h2>
      </div>
      <div className="position-relative section-description p-1 px-3">
        <small className="text-muted">
          Please note that some projects are private as they were internal projects developed for the companies I worked with. As a result, I am unable to share the source code for these projects.
          <br />
          <br />
          Additionally, there may be some projects that are outdated as they were personal projects not actively updated.
        </small>
      </div>
      <div className="projects_box">
        {/* <Card /> */}
        {data.projects.map((project, index) => {
          return (
            <Card
              {...project}
              key={index}
            />
          )
        })
        }
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: .5, ease: 'easeIn' } }}
        exit={{ scaleX: 1, transition: { duration: .5, ease: 'easeOut' } }}
        style={{ originX: isPresent ? 0 : 1, backgroundColor: "#05c46b" }}
        className="privacy-screen"
      />
      <motion.div className="progress" style={{ scaleX }} />
    </div>
  );
}




export default Projects;