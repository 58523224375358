import React from 'react'
import AboutSection from './AboutSection'
import ExperienceSection from './ExperienceSection'
import SkillSection from './SkillsSection'
import EducationSection from './EducationSection'
import { motion, useIsPresent } from "framer-motion";
import aboutSvg from '../../assets/blob1.svg'

const About = () => {
    const isPresent = useIsPresent();

    return (
        <>
            <img className='svg-top fadeRightMini' src={aboutSvg} alt="svg" />
            <img className='svg-bottom fadeLeftMini' src={aboutSvg} alt="svg" />
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX: 0, transition: { duration: .5, ease: 'easeIn' } }}
                exit={{ scaleX: 1, transition: { duration: .5, ease: "easeOut" } }}
                style={{ originX: isPresent ? 0 : 1, backgroundColor: '#3c40c6' }}
                className="privacy-screen"
            />
            <div className='about p-3 position-relative'>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                >
                    <AboutSection />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                >
                    <ExperienceSection />
                </motion.div>


                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                >
                    <SkillSection />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 2 }}
                >
                    <EducationSection />
                </motion.div>
            </div>
        </>
    )
}

export default About