import React, { useEffect, useState } from "react";
import { motion, useIsPresent, useScroll, useSpring } from "framer-motion";
import { ImageGallery } from "./ImageGallery";
import github from '../../assets/Github.svg';

const Card = ({ projectName, images, type, url, urlGit, shortDescription, description }) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
    };

    const [canSwipe, setCanSwipe] = useState(true);

    //Prevent card swipe when gallery is being swiped
    const onGallerySwipe = (value) => {
        setCanSwipe(value)
    }

    const [itemOnTop, setItemOnTop] = useState(false);


    return (
        <motion.div
            layout
            // transition={{
            //     x: { type: "spring", stiffness: 300, damping: 30 },
            //     opacity: { duration: 0.2 }
            // }}
            // drag='y'
            // dragConstraints={{ left: 0, right: 0 }}
            // dragConstraints={{ top: 0, bottom: 0 }}
            // dragElastic={1}
            // onDragEnd={(e, { offset, velocity }) => {
            //     if (!canSwipe) return;
            //     const swipe = swipePower(offset.y, velocity.y);

            //     if (swipe < -swipeConfidenceThreshold) {
            //         // setIsOpen(false);
            //         //SWIPE DOWN
            //     } else if (swipe > swipeConfidenceThreshold) {
            //         //Swipe up
            //         setIsOpen(false);
            //     }
            // }}
            data-isopen={isOpen}
            transition={{
                duration: .3,
            }}
            className="projects_item"
            onClick={(e) => {
                setIsOpen(true);
            }}
        >
            <motion.div
                layout
                className="projects-item_link"
                data-isopen={isOpen}
                initial={{ opacity: 0, translateX: 100, visibility: 'hidden' }}
                whileInView={{ opacity: 1, translateX: 0, visibility: 'visible', }}
                viewport={{ once: true }}
                transition={{
                    // duration: .3,
                    translateX: {
                        duration: .3,
                        // type: "spring",
                        // stiffness: 40,
                    },
                    opacity: { duration: .7 }
                }}
            >
                <motion.div layout='position' className="projects-item_date">
                    <span className="fw-bold" style={{ fontSize: '15px' }}>{type}</span>
                </motion.div>
                <motion.div role="button" layout='position'
                    initial={{ opacity: 0, position: 'fixed', fontSize: '20px' }}
                    animate={{ opacity: isOpen ? 1 : 0, top: '5px', right: '8px' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                    }}
                >
                    <i className="far fa-times-circle" />
                </motion.div>

                <motion.div
                    style={{
                        height: '2px',
                        width: '100px',
                        backgroundColor: '#3ecd5e',
                        marginBottom: '10px',
                    }}
                    layout
                    animate={{
                        width: isOpen ? '100%' : '100px',
                        transition: { duration: .5 }
                    }}

                />

                <motion.div initial={{ opacity: 0, translateX: 100 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    // viewport={{ once: true }}
                    transition={{
                        duration: .3,
                        delay: .2,
                        translateX: {
                            duration: .3,
                            // type: "spring",
                            // stiffness: 40,
                        },
                        opacity: { duration: .7 }
                    }} layout='position' className="projects-item_title" viewport={{ once: true }}>

                    {projectName}
                </motion.div>
                <motion.p className="text-muted" layout='position'>
                    {isOpen ? description : shortDescription}
                </motion.p>
                {images.length > 0 &&
                    <motion.div
                        layout='position'
                        animate={{
                            height: isOpen ? '500px' : '0px',
                            opacity: isOpen ? 1 : 0,
                        }}
                        transition={{
                            delay: isOpen ? .3 : 0,
                            height: { duration: .3 },
                            opacity: { duration: isOpen ? 1 : 0 }
                        }}
                        className="project-gallery-container">
                        <ImageGallery visible={isOpen} images={images} onSwipe={onGallerySwipe} />
                    </motion.div>
                }
                <motion.div initial={{ opacity: 0, translateX: 200 }}
                    whileInView={{ opacity: 1, translateX: 0 }}
                    // viewport={{ once: true }}
                    transition={{
                        duration: .3,
                        delay: .4,
                        translateX: {
                            duration: .3,
                            // type: "spring",
                            // stiffness: 40,
                        },
                        opacity: { duration: .7 }
                    }} viewport={{ once: true }} className="project-button-container mt-auto" data-isopen={isOpen}>
                    {!isOpen && <motion.div
                        layout='position'
                        role="button"
                        className="project-card-button icon"
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0 }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsOpen((prev) => !prev);
                        }}
                    >
                        Details
                    </motion.div>}
                    {url && <motion.div
                        layout='position'
                        role="button"
                        className="project-card-button icon"
                        whileTap={{ scale: 0.9 }}
                        // transition={{ duration: .3, delay: isOpen ? .5 : 0 }}
                        transition={{ duration: 0 }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(url, '_blank');
                        }}
                    >
                        Open
                    </motion.div>}
                    {urlGit && <motion.div
                        layout='position'
                        role="button"
                        className="project-card-button icon"
                        whileTap={{ scale: 0.9 }}
                        // transition={{ duration: .3, delay: isOpen ? .5 : 0 }}
                        transition={{ duration: 0 }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(urlGit, '_blank');
                        }}
                    >
                        <img src={github} alt="github-icon" />
                    </motion.div>}
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default Card