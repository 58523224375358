import linkedin from './assets/LinkedIN.svg'
import github from './assets/Github_white.svg'

export default {
    socials: [
        {
            "alt": "github icon",
            "icon": github,
            "link": "https://github.com/cossminuu"
        },
        {
            "alt": "linkedin icon",
            "icon": linkedin,
            "link": "https://www.linkedin.com/in/cosmin-dumitriu-b984161ba/"
        }
    ],

    ///////////////////////////// EXPERIENCE SECTION DETAILS ////////////////////////////
    education: [{
        id: 0,
        specialization: "Web, Apps & Digital Media",
        universityName: "Mohole School - Milano",
        url: "https://scuola.mohole.it",
        years: "2019-2020",
        descriptionList: [
            {
                id: 1,
                title: 'Modern JavaScript:',
                description: 'Comprehensive exploration of advanced JavaScript concepts, including ES6+ syntax, asynchronous programming, functional programming, and frameworks/libraries like React.js or Vue.js. Practical application of JavaScript in web development projects.'
            },
            {
                id: 2,
                title: 'Web Design:',
                description: 'In-depth study of web design principles, including user interface (UI) and user experience (UX) design. Focus on creating visually appealing, intuitive, and responsive web interfaces.'
            },
            {
                id: 3,
                title: 'Mobile App Development:',
                description: 'In-depth understanding of mobile app development principles, frameworks, and best practices.'
            },
            {
                id: 4,
                title: 'WordPress & PHP:',
                description: 'In-depth study of WordPress as a content management system (CMS) and PHP as a server-side scripting language.'
            },
            {
                id: 5,
                title: 'Workshops:',
                description: 'Engaging workshops focusing on industry trends, emerging technologies, and practical skills.'
            }
        ]
    },
    {
        id: 1,
        specialization: "Web, Apps & Digital Media",
        universityName: "Mohole School - Milano",
        url: "https://scuola.mohole.it",
        years: "2018-2019",
        descriptionList: [
            {
                id: 2,
                title: 'HTML & CSS:',
                description: 'Introduction to web development fundamentals, including HTML, CSS, and responsive design. Learn how to create visually appealing and user-friendly websites using industry-standard practices.'
            },
            {
                id: 3,
                title: 'JavaScript:',
                description: 'Introduction to JavaScript, the programming language of the web. Cover the fundamentals of JavaScript, including variables, data types, control flow, functions, and object-oriented programming concepts.'
            },
            {
                id: 4,
                title: 'Graphic Design (Illustrator, Photoshop):',
                description: 'Introduction to graphic design principles and industry-standard tools like Adobe Illustrator and Photoshop. Learn how to create digital graphics, logos, and images for web and print.'
            },
            {
                id: 6,
                title: 'Marketing:',
                description: 'Introduction to marketing principles and strategies for the web. Understand the basics of digital marketing, including SEO, social media marketing, content marketing, and analytics.'
            },
            {
                id: 7,
                title: 'Workshops:',
                description: 'Engaging workshops focusing on industry trends, emerging technologies, and practical skills. '
            }
        ]
    }
    ],
    ///////////////////////////// SKILLS LIST ////////////////////////////
    skills: [
        {
            title: 'Technical',
            list: [
                'HTML5',
                'CSS3',
                'JavaScript',
                'TypeScript',
                'React JS',
                'React Native',
                'Redux',
                'Vue JS',
                'Jest',
                'React Testing Library',
                'Bootstrap',
                'Core-UI',
                'Material-UI',
                'Git'
            ]
        },
        {
            title: 'Soft',
            list: [
                'Communication',
                'Collaboration and Teamwork',
                'Problem-Solving',
                'Adaptability',
                'Time Management',
                'Attention to Detail',
                'Creativity',
                'Critical Thinking',
                'Emotional Intelligence',
                'Continuous Learning'
            ]
        },
        {
            title: 'Design',
            list: [
                'Adobe Illustrator',
                'Figma',
                'UI/UX',
                'Wireframing',
                'Prototyping',
                'Responsive Design'
            ]

        },
        {
            title: 'Languages',
            list: [
                'Romanian',
                'English',
                'Italian'
            ]
        },

    ],

    work: [
        {
            id: 0,
            position: "Frontend Developer",
            jobType: "Full-Time",
            company: "L-Lynch Plant Hire & Haulage LTD / Hemel Hempstead, UK",
            url: "https://www.l-lynch.com/",
            years: "01/2022 - present",
            responsabilities: [
                "Develop and maintain robust frontend components and user interfaces for internal company projects, including a comprehensive business management dashboard with a job posting section, seamless machine location integration using Google Maps, efficient user management features, streamlined payroll management, intuitive scheduler for working shifts and more.",
                "Utilize JavaScript, React.js, and React Native frameworks to create interactive and responsive user interfaces.",
                "Collaborate with cross-functional teams, including designers and backend developers, to translate project requirements and design mockups into functional frontend solutions.",
                "Implement core-ui components and libraries to ensure consistency and efficiency in frontend development.",
                "Follow Scrum methodologies to plan and execute project sprints, participate in daily stand-ups, and contribute to sprint reviews and retrospectives.",
                "Test and debug frontend components across various devices and browsers to ensure cross-browser compatibility and a seamless user experience.",
            ]
        },
        {
            id: 1,
            position: "Junior Web Developer",
            jobType: "Full-Time",
            company: 'XRF Digital - Web Agency / Southam, UK',
            url: 'https://xrf.digital/',
            years: '05/2021 - 12/2021',
            responsabilities: []
        },
        {
            id: 2,
            position: "Junior Frontend Developer - React",
            jobType: "Full-Time",
            company: 'Netduma LTD / Cambridge, UK',
            url: 'https://netduma.com/',
            years: '03/2021 - 05/2021',
            responsabilities: []
        },
        {
            id: 3,
            position: "Coordinator on site / recruiter",
            jobType: "Full-Time",
            company: "Orbital Recruitment LTD / London, Uk",
            url: "https://www.orbital-recruitment.co.uk/",
            years: "04/2018-08/2018",
            responsabilities: [
                "Processing and organization of work shifts (Biopanel)",
                "Accounting",
                "Contract management and documentation relating to the hiring process"
            ]
        },
    ],
    ///////////////////////////// PORTFOLIO SECTION DETAILS ////////////////////////////
    projects: [
        {
            projectName: "Lynch Customer Dashboard - React",
            images: [
                '/projects/customer_dashboard.png',
                '/projects/customer_dashboard0.png',
                '/projects/customer_dashboard1.png',
                '/projects/customer_dashboard3.png',
                '/projects/customer_dashboard4.png',
                '/projects/customer_dashboard2.png',
                '/projects/customer_dashboard6.png'],
            type: "Web",
            shortDescription: 'Lynch Customer Portal is a platform built with React JS and Core UI.',
            description: 'The Lynch Customer Portal is a platform built with React JS and Core UI, combining the power of both technologies. React JS provides an interactive and dynamic user interface, while Core UI offers pre-designed components for a polished design. This combination results in a user-friendly and visually appealing portal where customers can efficiently manage their accounts, access machine information, telematics data, documents and much more.',
            url: "https://l-lynchapi.co.uk/CustomerPortal/",
            urlGit: "https://github.com/Cosmind0"
        },
        {
            projectName: "Lynch Customer App - React Native",
            images: [
                "/projects/lynch-app.png",
                "/projects/lynch-app1.png",
                "/projects/lynch-app2.png",
                "/projects/lynch-app3.png",
                "/projects/lynch-app4.png",
                "/projects/lynch-app5.png",
                "/projects/lynch-app6.png",
                "/projects/lynch-app7.png",
                "/projects/lynch-app8.png",
                "/projects/lynch-app9.png",
                "/projects/lynch-app10.png",
                "/projects/lynch-app11.png",
                "/projects/lynch-app12.png",
            ],
            type: "Mobile App",
            shortDescription: 'Lynch Customer app is a cutting-edge solution built with React Native.',
            description: 'The Lynch Customer mobile app is a cutting-edge solution built with React Native, offering a seamless and intuitive user experience. It provides Lynch customers with convenient access to account management features, machine details, telematics data, and much more. With its cross-platform capabilities and native-like performance, the app ensures a responsive and efficient experience on both iOS and Android devices.',
            url: "",
            urlGit: "https://github.com/Cosmind0",
        },
        {
            projectName: "Lynch Operator Dashboard - React",
            images: ['/projects/operator_portal.png',
                '/projects/operator_portal1.png',
                '/projects/operator_portal2.png',
                '/projects/operator_portal3.png',
                '/projects/operator_portal4.png',
                '/projects/operator_portal5.png',
            ],
            type: "Web",
            shortDescription: 'Lynch Operator Portal is a platform built with React JS and Core UI.',
            description: 'The dashboard is a web-based application developed using React and Core UI. It includes features such as operator location tracking, job management, recruitment, administration, scheduler, payroll timesheets, notifications, and chat. The dashboard provides a user-friendly interface and ensures seamless management of essential processes within the company. It improves productivity, transparency, and efficiency across departments.',
            url: "",
            urlGit: "https://github.com/Cosmind0"
        },
        {
            projectName: "How App - React Native",
            images: ['/projects/how.jpg', '/projects/how1.jpg', '/projects/how2.jpg', '/projects/how3.jpg', '/projects/how4.jpg', '/projects/how5.jpg', '/projects/how6.jpg'],
            type: "Mobile App",
            shortDescription: 'React Native app focused on wellbeing audio content.',
            description: "The app incorporates features such as audio playback controls, bookmarking, and integration with device notifications. Users can play, pause, and skip audios, bookmark their favorite ones for future reference, create playlists, and receive reminders or prompts to stay engaged with their personal development journey. In addition to its rich feature set, the app also integrates Google Pay and Apple Pay, providing users with secure and convenient payment options.",
            url: "https://apps.apple.com/gb/app/house-of-wellbeing/id1596093967",
            urlGit: "",
        },
        {
            projectName: "PW Racing",
            images: ['/projects/pwr.png', '/projects/pwr1.png', '/projects/pwr2.png'],
            type: "Web",
            shortDescription: '',
            description: '',
            url: "https://paulwoodracing.com/",
            urlGit: "",
        },
        {
            projectName: "Music Player - React",
            images: ["/projects/music-player.png", "/projects/music-player1.png"],
            type: 'Web',
            shortDescription: '',
            description: '',
            url: "https://audiobeat-cb4d2.web.app/",
            urlGit: "https://github.com/cossminuu/MusicPlayer-AudioBeat",
        },
        {
            projectName: "Netflix Clone - React",
            images: [
                '/projects/netflix-clone.png',
                '/projects/netflix-clone1.png',
                '/projects/netflix-clone2.png',
                '/projects/netflix-clone3.png',
            ],
            type: 'Web',
            shortDescription: '',
            description: '',
            url: "https://netflix-clone-2-ff735.web.app",
            urlGit: "https://github.com/cossminuu/Netflix-Clone"
        },
        {
            projectName: "Social Network - React",
            images: [],
            type: "Web",
            shortDescription: '',
            description: '',
            url: "https://socialapp-72a75.web.app/",
            urlGit: "https://github.com/cossminuu/SocialNetwork",
        },
        {
            projectName: "Fuori Mohole - WordPress Theme",
            images: ['/projects/moholepeople.png'],
            type: 'Web',
            shortDescription: '',
            description: '',
            url: "https://www.moholepeople.it/fuorimohole/"
        },
        {
            projectName: "Tourist Finder - WorpPress Theme",
            images: [],
            type: 'Web',
            shortDescription: '',
            description: '',
            url: "http://www.moholepeople.it/adottaunturista/index.php"
        }
    ],
}