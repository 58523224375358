import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const _navItems = [
    {
        name: 'Home',
        path: '/home',
        icon: 'far fa-home',
        color: "#dc143c"
    },
    {
        name: 'About',
        path: '/about',
        icon: 'far fa-info',
        color: "#3c40c6"
    },
    {
        name: 'Projects',
        path: '/projects',
        icon: 'far fa-list',
        color: "#ffa801"
    }
]

const BottomNav = () => {
    const location = useLocation();
    return (
        <div className='bottom-nav-container fadeLeftMini'>
            <div className="navigation">
                <ul>
                    {
                        _navItems.map((item, index) => {
                            return (
                                <li key={index} className={`list ${location.pathname.includes(item.path) ? 'active' : ''}`} >
                                    <Link to={item.path}>
                                        <span className="icon"><i className={item.icon}></i></span>
                                        <span className="title">{item.name}</span>
                                    </Link>
                                </li>
                            )
                        }
                        )
                    }
                    <div className="indicator"></div>
                </ul>
            </div>
        </div>
    )
}

export default BottomNav