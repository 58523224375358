import './App.css';
import * as React from "react";
import Projects from './pages/Projects/Projects'
import Home from './pages/Home'
import About from './pages/About/About';
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes, Navigate } from "react-router-dom";


export default function App() {

  const location = useLocation();

  const element = useRoutes([
    {
      path: "*",
      element: (
        <Navigate to="/home" replace />
      )
    },
    {
      path: "/home",
      element: <Home />
    },
    {
      path: "/about",
      element: <About />

    },
    {
      path: "/projects",
      element: <Projects />
    },
  ]);



  if (!element) return null;

  return (
    <div className="App">
      <div className='content' key={location.pathname}>
        <AnimatePresence mode="wait"
        //  initial={false}
        >
          {React.cloneElement(element)}
        </AnimatePresence>
      </div>
    </div>
  );
}
